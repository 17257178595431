<template>
    <div class="container pb-5">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">Eclipse Website</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">The Eclipse company website. This site has been put together using <code>Wordpress</code>.</p>
      <p>Wordpress | PHP | jQuery | Wordpress Plugins</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <p class="fst-italic fw-lighter">- Access granted to site by request only</p>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="#" target="_blank"><img src="../assets/img/ewithumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="cv site" width="700" height="900" loading="lazy"></a>
      </div>
    </div>
  </div>
</div>
</template>


<script>
</script>