<template>
    <div class="container pb-5">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">Shopper</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">This site has been put together using <code>Laravel 11</code>. It has a backend CMS built using <code>Filament PHP</code> and a shopping cart driven by <code>Laravel Livewire</code>. The site uses <code>Tailwind CSS</code>. The site allows you to log into the backend CMS and create products for the Shop front.</p>
      <p>Laravel 11 | Filament  PHP | Livewire | TailwindCSS | Composer | NodeJS - NPM</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <button type="button" class="btn btn-outline-secondary btn-lg px-4">Shopper</button>
        <a href="/files/shopper.apk" download="shopper.apk"><img src="../assets/img/logos/play.png" alt="download shopper android app" width="48" height="48"></a>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="https://shopper.saqibofficial.com" target="_blank"><img src="../assets/img/shopperthumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="cv site" width="700" height="900" loading="lazy"></a>
      </div>
    </div>
  </div>
</div>
</template>


<script>
</script>