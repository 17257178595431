<template>
    <div class="container pb-5">
  <div class="px-4 py-5 text-center text-light h-50">
    <h2 class="display-4 fw-bold">Indie</h2>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">This site has been purely put together using <code>Laravel 11</code>. The Backend admin section harnesses the power of <code>InertiaJS</code> to provide an SPA experience. It allows users to login, browse characters and 'favourite' them. Admin Users can configure users, roles/permissions, user roles/permissions and run syncs for <code>api</code> data. <code>Laravel Reverb</code>, <code>Laravel Echo</code> and <code>php-flasher</code> are used to present real-time feedback to the user.</p>
      <p>Laravel 11 | Reverb | Echo | InertiaJS | TailwindCSS | Composer | NodeJS - NPM</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <button type="button" class="btn btn-outline-secondary btn-lg px-4">Indie</button>
        <a href="/files/indie.apk" download="indie.apk"><img src="../assets/img/logos/play.png" alt="download indie android app" width="48" height="48"></a>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container px-5">
        <a href="https://indie.saqibofficial.com" target="_blank"><img src="../assets/img/indiethumbnail.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="cv site" width="700" height="900" loading="lazy"></a>
      </div>
    </div>
  </div>
</div>
</template>


<script>

</script>