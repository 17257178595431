import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HView from '../views/HView.vue'
import PortfolioView from '../views/PortfolioView.vue'
const rmapk = { template: '<div>rmapk</div>' }
const routes = [
  // {
  //   path: '/h',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'hv',
    component: HView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/portfolio',
    name: 'gigs',
    component: PortfolioView
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: HView 
  },
  {
    path:'/rmapk',
    beforeEnter() { location.href = '/public/app-release.apk' },
    component: rmapk
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
